<template>
    <div>Reports</div>
</template>

<script>
export default {
    name: "Reports"
}
</script>

<style scoped>

</style>
